import API from '../../axios/API'
import Auth from '../../modules/Auth'

export const getCartByUserId = () => dispatch => {
  let userId = Auth.getUserId()
  dispatch({
    type: GET_CART_BY_USERID_BEGIN
  })
  return API({
    method: 'GET',
    url: `users/${userId}/cart`
  })
    .then(res => {
      dispatch({
        type: GET_CART_BY_USERID_SUCCESS,
        payload: res
      })
      return res
    })
    .catch(error => {
      dispatch({
        type: GET_CART_BY_USERID_FAIL,
        payload: {
          error
        }
      })
      return error
    })
}
export const clearCart = () => dispatch => {
  dispatch({
    type: CLEAR_CART
  })
}

export const clearItem = productId => dispatch => {
  dispatch({
    type: CLEAR_ITEM_CART_BEGIN
  })
  let items = JSON.parse(localStorage.getItem('cart')) || {}
  console.log(productId)
  console.log(items)
  if (JSON.stringify(items) == '{}') {
    return
  } else {
    let products = items.products
    let _products = products.filter((e, index) => e.id !== productId)
    items.products = _products
    let _total = items.products.reduce((acc, a) => acc + a.subTotal, 0)
    let _totalQty = items.products.reduce((acc, a) => acc + a.count, 0)
    items.total = _total
    items.totalQty = _totalQty
    localStorage.setItem('cart', JSON.stringify(items))
  }
  if (items.products.length == 0) {
    localStorage.removeItem('cart')
    window.location.href = '/'
  }
  dispatch({
    type: CLEAR_ITEM_CART_SUCCESS,
    payload: items
  })
}

export const postCart =
  (productId, count, _color, price, image, name) => dispatch => {
    dispatch({
      type: POST_CART_BEGIN
    })
    let items = JSON.parse(localStorage.getItem('cart')) || {}
    count = parseInt(count)
    if (JSON.stringify(items) == '{}') {
      let sub = parseInt(price) * parseInt(count)
      let product = {
        id: productId,
        name: name,
        count: count,
        price: parseInt(price),
        subTotal: sub,
        color: _color,
        image: image
      }
      let _products = []
      _products.push(product)
      items = {
        products: _products
      }
    } else {
      let products = items.products
      let _product = products.filter((e, index) => e.id == productId)[0]
      let index = products.indexOf(_product)
      if (_product !== undefined) {
        _product.count = parseInt(count)
        let _sub = parseInt(price) * parseInt(count)
        _product.subTotal = _sub
        _product.count = count
        products.splice(index, 1, _product)
      } else {
        let _sub = parseInt(price) * parseInt(count)
        let product = {
          id: productId,
          name: name,
          count: count,
          price: parseInt(price),
          subTotal: _sub,
          color: _color,
          image: image
        }
        products.push(product)
      }
      items.products = products
    }
    let _total = items.products.reduce((acc, a) => acc + a.subTotal, 0)
    let _totalQty = items.products.reduce((acc, a) => acc + a.count, 0)
    items.total = _total
    items.totalQty = _totalQty
    dispatch({
      type: POST_CART_SUCCESS,
      payload: items
    })
    localStorage.setItem('cart', JSON.stringify(items))
  }

export const POST_CART_BEGIN = 'POST_CART_BEGIN'
export const POST_CART_SUCCESS = 'POST_CART_SUCCESS'

export const CLEAR_ITEM_CART_BEGIN = 'CLEAR_ITEM_CART_BEGIN'
export const CLEAR_ITEM_CART_SUCCESS = 'CLEAR_ITEM_CART_SUCCESS'

export const POST_CART_FAIL = 'POST_CART_FAIL'
export const CLEAR_CART = 'CLEAR_CART'

export const GET_CART_BY_USERID_BEGIN = 'GET_CART_BY_USERID_BEGIN'
export const GET_CART_BY_USERID_SUCCESS = 'GET_CART_BY_USERID_SUCCESS'
export const GET_CART_BY_USERID_FAIL = 'GET_CART_BY_USERID_FAIL'
