// Route Views
import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';

// Layout Types
import BaseLayout from '../layouts/BaseLayout';

import CartContainer from '../views/Cart/CartContainer';
import ProfileContainer from '../views/Profile/ProfileContainer';

var PrivateRoutes = [
  {
    path: '/cart',
    layout: BaseLayout,
    component: CartContainer,
  },
  {
    path: '/profile',
    layout: BaseLayout,
    component: ProfileContainer,
  },
];

export default PrivateRoutes;
