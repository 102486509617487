import {
  getAllProducts,
  applyFilters,
} from "../../redux/actions/productAction";
import { getAllCategories } from "../../redux/actions/categoryAction";
import { connect } from "react-redux";
import Category from "./Category";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = (state) => ({
  categories: state.category.categories,
  products: state.product.products,
  loading: state.category.loading,
  lang: state.lang,
  currency: state.currency,
});
const mapDispatchToProps = (dispatch) => ({
  getAllCategories: () => dispatch(getAllCategories()),
  getAllProducts: () => dispatch(getAllProducts()),
  applyFilters: (filter_string) => dispatch(applyFilters(filter_string)),
  postCart: (productId, count, color, price, image, name) =>
    dispatch(postCart(productId, count, color, price, image, name)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Category);
