import React, { Component } from 'react'
import Heading from '../../components/Heading'
import { Button } from 'react-bootstrap'
import CalculateTax from '../../utils/CalculateTax'
import EmptyCart from '../../assets/images/empty_cart.png'
import CartService from '../../axios/cartService'
import { toast } from 'react-toastify'
import UserService from '../../axios/userService'
import { BASE_URL } from '../../base'

class Profile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      profile: {},
      myOrders: []
    }
    this.saveMyProfile = this.saveMyProfile.bind(this)
  }

  componentDidMount = () => {
    this.getMyProfile()
  }

  getMyProfile = () => {
    UserService.myProfile()
      .then(resp => {
        this.setState({ profile: resp[0] })
        UserService.myOrder().then(res => {
          console.log(res)
          this.setState({ myOrders: res })
        })
      })
      .catch(er => console.error(er))
  }

  cashOrder = id => {
    UserService.payStribe(id)
      .then(resp => {
        console.log(resp)
        if (resp.success) {
          window.open(resp.data['0'], '_blank')
        }
      })
      .catch(er => console.error(er))
  }

  saveMyProfile = () => {
    let payload = {
      name: this.state.profile.name,
      email: this.state.profile.email,
      phone: this.state.profile.phone,
      city: this.state.profile.city,
      role: this.state.profile.role,
      address: this.state.profile.address,
      birthday: this.state.profile.birthday
    }
    console.log(payload)
    var data = new FormData()
    Object.keys(payload).forEach(e => {
      data.append(e, payload[e])
    })
    UserService.editUser(data).then(resp => {
      console.log(resp)
    })
  }
  render () {
    return (
      <div className='shopping--cart' data-aos='fade-up'>
        <div className='container'>
          <div className='shopping--cart--container'>
            <div className='row my-3'>
              <Heading
                title={
                  this.props.lang.lang == 'en' ? 'My Profile' : 'الحساب الشخصي'
                }
                data-aos='fade-up'
              />
            </div>
            <div style={{ height: 'auto' }}>
              <div className='cold-md-6'>
                <form>
                  <div class='form-group'>
                    <label for='exampleInputEmail1'>Email address</label>
                    <input
                      type='email'
                      class='form-control'
                      id='exampleInputEmail1'
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            email: event.target.value
                          }
                        })
                      }}
                      value={this.state.profile.email}
                      aria-describedby='emailHelp'
                      placeholder='Enter email'
                    />
                  </div>
                  <div class='form-group'>
                    <label for='exampleInputEmail1'>Name</label>
                    <input
                      type='text'
                      class='form-control'
                      id='exampleInputEmail1'
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            name: event.target.value
                          }
                        })
                      }}
                      value={this.state.profile.name}
                      aria-describedby='emailHelp'
                      placeholder='Enter email'
                    />
                  </div>
                  <div class='form-group'>
                    <label for='exampleInputPassword1'>Phone</label>
                    <input
                      type='text'
                      value={this.state.profile.phone}
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            phone: event.target.value
                          }
                        })
                      }}
                      class='form-control'
                      id='exampleInputPassword1'
                      placeholder='Password'
                    />
                  </div>
                  <div class='form-group'>
                    <label for='exampleInputPassword1'>City</label>
                    <input
                      type='text'
                      value={this.state.profile.city}
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            city: event.target.value
                          }
                        })
                      }}
                      class='form-control'
                      id='exampleInputPassword1'
                      placeholder='City'
                    />
                  </div>
                  <div class='form-group'>
                    <label for='exampleInputPassword1'>Address</label>
                    <input
                      type='text'
                      value={this.state.profile.address}
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            address: event.target.value
                          }
                        })
                      }}
                      class='form-control'
                      id='exampleInputPassword1'
                      placeholder='Address'
                    />
                  </div>
                  <div class='form-group'>
                    <label for='exampleInputPassword1'>Birthday</label>
                    <input
                      type='date'
                      value={this.state.profile.birthday}
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            birthday: event.target.value
                          }
                        })
                      }}
                      class='form-control'
                      id='exampleInputPassword1'
                      placeholder='Birthday'
                    />
                  </div>
                  <button
                    type='button'
                    onClick={this.saveMyProfile}
                    class='btn btn-primary'
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='shopping--cart--container'>
            <div className='row my-5'>
              <Heading
                title={this.props.lang.lang == 'en' ? 'My Orders' : 'طلباتي'}
                data-aos='fade-up'
              />
            </div>
            <div style={{ height: 'auto' }}>
              {!!this.state.myOrders &&
                this.state.myOrders.length > 0 &&
                this.state.myOrders.map(order => {
                  return (
                    <div
                      className='row my-3'
                      style={{
                        border: '1px solid orange',
                        borderRadius: '20px',
                        padding: '1em'
                      }}
                    >
                      <div className='col-md-6'>
                        <strong style={{ margin: '0 1em' }}>
                          {this.props.lang.lang == 'en' ? 'Date' : 'التاريخ'}
                        </strong>
                        {order.date}
                      </div>
                      <div className='col-md-3'>
                        <strong style={{ margin: '0 1em' }}>
                          {this.props.lang.lang == 'en'
                            ? 'Status'
                            : 'حالة الطلب'}
                        </strong>
                        <span
                          style={{
                            backgroundColor: order.order_status?.color
                          }}
                        >
                          {order.order_status?.name}
                        </span>
                      </div>
                      <div className='col-md-3'>
                        <button
                          type='button'
                          disabled={order.is_paid === 1}
                          onClick={() => this.cashOrder(order.id)}
                          class='btn btn-primary'
                        >
                          {this.props.lang.lang == 'en' ? 'Pay' : 'دفع'}
                        </button>
                      </div>
                      <div className='col-md-6'>
                        <strong style={{ margin: '0 1em' }}>
                          {this.props.lang.lang == 'en' ? 'Address' : 'العنوان'}
                        </strong>
                        {order.address}
                      </div>
                      <div className='col-md-6'>
                        <strong style={{ margin: '0 1em' }}>
                          {this.props.lang.lang == 'en'
                            ? 'Sub Total'
                            : 'المجزأ'}
                        </strong>
                        {order.sub_total}
                      </div>
                      <div className='col-md-3'>
                        <strong style={{ margin: '0 1em' }}>
                          {this.props.lang.lang == 'en' ? 'Total' : 'الإجمالي'}
                        </strong>
                        {order.total}
                      </div>
                      <div className='col-md-12'>
                        <table className='table table-borderd table-striped'>
                          <thead>
                            <tr>
                              <th>
                                {this.props.lang.lang == 'en'
                                  ? 'Product Name'
                                  : 'اسم المنتج'}
                              </th>
                              <th>
                                {this.props.lang.lang == 'en'
                                  ? 'Product Description'
                                  : 'وصف المنتج'}
                              </th>
                              <th>
                                {this.props.lang.lang == 'en'
                                  ? 'Price'
                                  : 'السعر'}
                              </th>
                              <th>
                                {this.props.lang.lang == 'en'
                                  ? 'Image'
                                  : 'الصورة'}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {order?.items.map(item => {
                              return (
                                <tr>
                                  <td>
                                    {this.props.lang.lang == 'en'
                                      ? item.product.en_name
                                      : item.product.ar_name}
                                  </td>
                                  <td>
                                    {this.props.lang.lang == 'en'
                                      ? item.product.en_description
                                      : item.product.ar_description}
                                  </td>
                                  <td>
                                    {this.props.lang.lang == 'en'
                                      ? item.product.selling_price
                                      : item.product.selling_price}
                                  </td>
                                  <td>
                                    <img
                                      src={`${BASE_URL}upload/${item.product.image}`}
                                      alt=''
                                      className='img-fluid'
                                    />
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Profile
