import {
  connect
} from "react-redux";
import NavBar from "./NavBar";
import {
  getCartByUserId
} from "../../redux/actions/cartAction";
import {
  getLang,
  setLang,
  setActiveLink,
  getActiveLink
} from "../../redux/actions/langAction"
import {
  setCurrency,
  getCurrency
} from '../../redux/actions/CurrencyAction'
const mapStoreToProps = state => ({
  // departments: state.department.departments,
  cart: state.cart,
  lang: state.lang,
  activeLink: state.activeLink,
  currency: state.currency
});

const mapDispatchToProps = dispatch => ({
  // getCartByUserId: () => dispatch(getCartByUserId()),
  setLang: (lang) => dispatch(setLang(lang)),
  getLang: dispatch(getLang),
  setActiveLink: (link) => dispatch(setActiveLink(link)),
  getActiveLink: dispatch(getActiveLink),
  setCurrency: (curr) => dispatch(setCurrency(curr)),
  getCurrency: dispatch(getCurrency)
});

export default connect(mapStoreToProps, mapDispatchToProps)(NavBar);