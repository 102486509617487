import {
  SET_LANG,
  GET_LANG,
  SET_ACTIVE_LINK,
  GET_ACTIVE_LINK,
} from "../actions/langAction";

const initialState = {
  lang: "en",
  activeLink: "home",
  loading: false,
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANG:
      console.log("action", action.payload);
      return {
        ...state,
        lang: action.payload,
      };
    case GET_LANG:
      return {
        ...state,
      };
    case SET_ACTIVE_LINK:
      return {
        ...state,
        activeLink: action.payload,
      };
    case GET_ACTIVE_LINK:
      return {
        ...state,
      };
    default:
      return state;
  }
};
