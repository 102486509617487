import React, { Component } from "react";

class Filter extends Component {
  constructor(props) {
    console.log("filt", props);
    super(props);
    this.state = {
      categories: this.props.categories,
      active: this.props.active,
    };
    this.setActive = this.setActive.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      console.log("up", this.props);
      this.setState({
        categories: this.props.categories,
        active: this.props.active,
      });
    }
  }
  setActive(id) {
    this.props.filterByCategory(id);
  }
  render() {
    console.log(this.state);
    return (
      <div class="sidebar_section" style={{ zIndex: "1" }}>
        <div class="sidebar_title">
          <h5>{this.props.lang == "en" ? `Categories` : `الأصناف`}</h5>
        </div>
        <ul class="sidebar_categories">
          {this.state.active === 0 && (
            <li className="active">
              {this.props.lang == "en" && (
                <span>
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </span>
              )}
              <a href="javascript:void(0)">
                {this.props.lang == "en" ? `All` : `الكل`}
              </a>
              {this.props.lang == "ar" && (
                <span>
                  <i
                    class="fa fa-angle-double-left"
                    style={{ marginLeft: "10px" }}
                    aria-hidden="true"
                  ></i>
                </span>
              )}
            </li>
          )}
          {this.state.active !== 0 && (
            <li>
              <a href="javascript:void(0)" onClick={() => this.setActive(0)}>
                {this.props.lang == "en" ? `All` : `الكل`}
              </a>
            </li>
          )}
          {this.state.categories &&
            this.state.categories.map((cat, index) => {
              if (this.state.active === cat.id)
                return (
                  <li className="active">
                    {this.props.lang == "en" && (
                      <span>
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    )}
                    <a href="javascript:void(0)">
                      {this.props.lang == "en"
                        ? `${cat.name} (${cat.product_count})`
                        : `${cat.name_a} (${cat.product_count})`}
                    </a>
                    {this.props.lang == "ar" && (
                      <span>
                        <i
                          class="fa fa-angle-double-left"
                          style={{ marginLeft: "10px" }}
                          aria-hidden="true"
                        ></i>
                      </span>
                    )}
                  </li>
                );
              else
                return (
                  <li>
                    {" "}
                    <a
                      href="javascript:void(0)"
                      onClick={() => this.setActive(cat.id)}
                    >
                      {this.props.lang == "en"
                        ? `${cat.name} (${cat.product_count})`
                        : `${cat.name_a} (${cat.product_count})`}
                    </a>{" "}
                  </li>
                );
            })}
        </ul>
      </div>
    );
  }
}

export default Filter;
