import { connect } from "react-redux";
import Profile from "./Profile";
import {
  getCartByUserId,
  postCart,
  clearCart,
} from "../../redux/actions/cartAction";

const mapStoreToProps = (state) => ({
  lang: state.lang,
  currency: state.currency,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStoreToProps, mapDispatchToProps)(Profile);
