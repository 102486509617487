import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Auth from "../../modules/Auth";

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    console.log("mobile", props);
    this.state = {};
  }
  render() {
    return (
      <div
        className={
          this.props.activeClass ? "hamburger_menu active" : "hamburger_menu"
        }
      >
        <div className="hamburger_close" onClick={this.props.onClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        <div className="hamburger_menu_content text-right">
          <ul className="menu_top_nav">
            <li className="menu_item">
              <Link to="/home">
                {this.props.lang.lang == "en" ? "home" : "الصفحة الرئيسية"}
              </Link>
            </li>
            <li className="menu_item">
              <Link to="/categories">
                {this.props.lang.lang == "en" ? "products" : "المنتجات"}
              </Link>
            </li>
            <li className="menu_item">
              <Link to="/categories">
                {this.props.lang.lang == "en" ? "categories" : "الأصناف"}
              </Link>
            </li>
            <li className="menu_item">
              <a
                onClick={() => this.props.setLanguage("en")}
                className={this.state.lang == "en" ? "active" : ""}
                href="#"
              >
                English
              </a>
            </li>
            <li className="menu_item">
              <a
                onClick={() => this.props.setLanguage("ar")}
                className={this.state.lang == "en" ? "active" : ""}
                href="#"
              >
                العربية
              </a>
            </li>
            <li className="menu_item">
              <a
                onClick={() => this.props.setCurrency("dollar")}
                className={this.state.lang == "en" ? "active" : ""}
                href="#"
              >
                USD ($)
              </a>
            </li>
            <li className="menu_item">
              <a
                onClick={() => this.props.setCurrency("pounds")}
                className={this.state.lang == "en" ? "active" : ""}
                href="#"
              >
                Yemen (YRI)
              </a>
            </li>
            {Auth.getUserDetails() !== undefined &&
              Auth.getUserDetails() !== null &&
              Auth.getToken() !== undefined && (
                <li className="mega-drop-down">
                  <a href="#">
                    {this.state.lang == "en"
                      ? `Welcome ${Auth.getUserDetails().name}`
                      : `مرحباً ${Auth.getUserDetails().name}`}
                  </a>
                  <div className="mega-menu">
                    <div className="mega-menu-wrap">
                      <div className="mega-menu-content">
                        <ul className="stander">
                          <li>
                            <a href="#" onClick={() => this.props.logout()}>
                              <i
                                className="fas fa-sign-in-alt mx-1"
                                aria-hidden="true"
                              />
                              {this.state.lang == "en"
                                ? "Logout"
                                : "تسجيل الخروج"}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              )}
            {(Auth.getUserDetails() === undefined ||
              Auth.getUserDetails() === null ||
              Auth.getToken() === undefined) && (
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.loginClicked();
                  }}
                >
                  {this.state.lang == "en" ? "Login" : "تسجيل الدخول"}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}
MobileMenu.propTypes = {
  activeClass: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MobileMenu;
