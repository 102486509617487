import React, { Component } from 'react'
import Heading from '../../components/Heading'
import CartItem from './CartItem'
import { Button } from 'react-bootstrap'
import CalculateTax from '../../utils/CalculateTax'
import EmptyCart from '../../assets/images/empty_cart.png'
import CartService from '../../axios/cartService'
import { toast } from 'react-toastify'
import UserService from '../../axios/userService'

class Cart extends Component {
  constructor (props) {
    super(props)
    console.clear()
    console.log('cart', props)
    this.state = {
      address: '',
      coupon: '',
      orderId: ''
    }
    this.setAddress = this.setAddress.bind(this)
    this.setCoupon = this.setCoupon.bind(this)
    this.checkout = this.checkout.bind(this)
  }
  setAddress = val => {
    this.setState({ address: val })
  }
  setCoupon = val => {
    this.setState({ coupon: val })
  }
  checkout = () => {
    console.log(this.props)
    let _products = this.props.cart?.products.map(e => {
      return {
        id: e.id,
        color: e.color,
        count: e.count
      }
    })
    let payload = {
      payment_method: 'cache',
      address:
        this.state.address ||
        JSON.parse(localStorage.getItem('auth'))?.user?.address,
      coupon: this.state.coupon,
      products: _products
    }
    let token = JSON.parse(localStorage.getItem('auth'))?.token
    if (
      JSON.parse(localStorage.getItem('auth'))
        ?.user?.roles.map(e => e.name)
        .includes('User')
    ) {
      CartService.CheckOut(payload, token)
        .then(resp => {
          this.props.clearCart()
          this.props.lang.lang == 'en'
            ? toast.success('Order has been submitted successfully')
            : toast.success('تم استلام طلبك بنجاح')
          this.setState({ orderId: resp.id })
        })
        .catch(error =>
          this.props.lang.lang == 'en'
            ? toast.error('An Error Occurred')
            : toast.error('لقد حدث خطأ ما')
        )
    } else toast.error("You don't have permissions to process this operation")
  }

  pay = () => {
    UserService.payStribe(this.state.orderId)
      .then(resp => {
        console.log(resp)
        if (resp.success) {
          window.open(resp.data['0'], '_blank')
        }
      })
      .catch(er => console.error(er))
  }
  render () {
    console.log('state', this.props.cart)
    const products = this.props.cart?.products
    const total = this.props.cart?.total
    const totalQty = this.props.cart?.totalQty
    const postCart = this.props.postCart
    return (
      <div className='shopping--cart' data-aos='fade-up'>
        <div className='container'>
          <div className='shopping--cart--container'>
            <div className='row '>
              <Heading
                title={
                  this.props.lang.lang == 'en'
                    ? 'Your Shopping Cart'
                    : 'سلة المنتجات الخاصة بك'
                }
                data-aos='fade-up'
              />
            </div>
            <div style={{ height: 30 }}></div>
            <CartItem
              currency={this.props.currency.currency}
              lang={this.props.lang.lang}
              items={products || {}}
              handleClick={this.props.postCart}
              clearItem={this.props.clearItem}
            />
            {products !== undefined && products !== null ? (
              <div
                className='d-flex flex-column justify-content-end align-items-end'
                style={{ paddingRight: 50 }}
              >
                <h3 style={{ textAlign: 'center' }}>
                  {this.props.lang.lang == 'en' && ` Total `}
                  <span style={{ color: '#FE4C50' }}>
                    {this.props.currency.currency == 'dollar'
                      ? `${total} $`
                      : `${(total * 250.27).toFixed(2)} YRI`}
                  </span>
                  {this.props.lang.lang == 'ar' && ` المجموع `}
                </h3>
                <hr />
                <Button
                  onClick={this.checkout}
                  variant='danger'
                  size='sm'
                  style={{ marginTop: 30 }}
                >
                  {this.props.lang.lang == 'en'
                    ? ` Confirm Checkout`
                    : 'تأكيد الطلب'}
                </Button>
                {!!this.state.orderId && (
                  <Button
                    onClick={this.pay}
                    variant='danger'
                    size='sm'
                    style={{ marginTop: 30 }}
                  >
                    {this.props.lang.lang == 'en'
                      ? ` Confirm Payment`
                      : 'تأكيد الدفع'}
                  </Button>
                )}
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={EmptyCart}
                  alt=''
                  style={{ maxHeight: 400, maxWidth: 400 }}
                  className='img-fluid'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Cart
