import React, { Component } from "react";
import { connect } from "react-redux";
import { userLogin } from "../../redux/actions/LoginAction";
import jumpTo from "../../modules/Navigation";
import Validator from "../../utils/Validator";
import { DEFAULT_RULE, EMAIL_RULE } from "../../utils/Validator/rule";
import PropTypes from "prop-types";
import LoadingButton from "../LoadingButton";
import LoginService from "./../../axios/LoginService";
import { toast } from "react-toastify";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      code: "",
      requireCode: false,
      loadingCode: false,
      loading: false,
      loading1: false,
      loading2: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  LoginType = (type) => {
    this.setState({ loading1: true });
    LoginService.LoginType(type)
      .then((res) => {
        console.log(res);
        this.setState({ loading1: false });
        var newWindow = window.open();
        newWindow.document.write(res);
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ loading1: false });
      });
  };

  handleSubmit = () => {
    const { email, password } = this.state;
    if (!Validator(email, EMAIL_RULE)) {
      console.log("email Error");
      return;
    }
    if (!Validator(password, DEFAULT_RULE)) {
      console.log("Password Error");
      return;
    }
    this.setState({ loading: true });
    this.props
      .userLogin(email, password)
      .then((res) => {
        this.setState({ loading: false });
        if (
          JSON.parse(localStorage.getItem("loginError"))?.message ==
          "Your account is not verified."
        ) {
          this.setState({ loading: false, code: "", requireCode: true });
        }
        if (!!JSON.parse(localStorage.getItem("auth")).token)
        {
          localStorage.removeItem("cart");
          window.location.reload();
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  handleSubmitCode = () => {
    this.setState({ loadingCode: true });
    const { email, code } = this.state;
    if (!Validator(code, DEFAULT_RULE)) {
      return;
    }
    if (!Validator(email, EMAIL_RULE)) {
      return;
    }
    LoginService.verifyCode(email, code)
      .then((res) => {
        this.setState({ loadingCode: false });
        if (
          JSON.parse(localStorage.getItem("loginError")).message ==
          "code is false"
        ) {
          this.setState({ loading: false, code: "" });
        }
        if (res) {
          toast.success("Please Login again.");
          localStorage.removeItem("loginError");
          this.setState({ loadingCode: false, code: "", requireCode: false });
        }
      })
      .catch((error) => {});
  };
  render() {
    return (
      <div>
        <div className="login-form">
          <h2>Login</h2>
          {this.state.requireCode ? (
            <>
              Please provide your email and verification code
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email "
                  id="UserName"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  autoComplete="false"
                />
                <i className="fa fa-user"></i>
              </div>
              <div className="form-group log-status">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Code"
                  id="code"
                  name="code"
                  value={this.state.code}
                  onChange={this.handleChange}
                  autoComplete="false"
                />
              </div>
              <LoadingButton
                type="button"
                className="log-btn"
                loading={this.state.loadingCode}
                onClick={() => this.handleSubmitCode()}
              >
                Submit
              </LoadingButton>
            </>
          ) : (
            <>
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email "
                  id="UserName"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  autoComplete="false"
                />
                <i className="fa fa-user"></i>
              </div>
              <div className="form-group log-status">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  id="Passwod"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  autoComplete="false"
                />
                <i className="fa fa-lock"></i>
              </div>
              <span className="alert">Invalid Credentials</span>
              <a
                className="link"
                href="#"
                onClick={this.props.forgotPasswordClicked}
              >
                Lost your password?
              </a>
              <LoadingButton
                type="button"
                className="log-btn"
                loading={this.state.loading}
                onClick={() => this.handleSubmit()}
              >
                Log in
              </LoadingButton>
              <button
                className="btn btn-wide"
                style={{
                  backgroundColor: "#0069d9",
                  color: "white",
                  width: "100%",
                  fontSize: "16px",
                  height: "40px",
                  borderRadius: "20px",
                  marginBottom: ".5em",
                }}
                disabled={this.state.loading1}
                onClick={() => this.LoginType("facebook")}
              >
                <span>
                  {!this.state.loading1
                    ? "Login with Facebook"
                    : "Logging with Facebook..."}
                  <svg
                    style={{
                      top: "-.1em",
                      position: "relative",
                      marginLeft: ".5em",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-facebook"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                  </svg>
                </span>
              </button>
              <button
                className="btn btn-wide"
                style={{
                  backgroundColor: "#0069d9",
                  color: "white",
                  width: "100%",
                  fontSize: "16px",
                  height: "40px",
                  borderRadius: "20px",
                }}
                disabled={this.state.loading1}
                onClick={() => this.LoginType("google")}
              >
                <span>
                  {!this.state.loading1
                    ? "Login with Google"
                    : "Logging with Google..."}
                  <svg
                    style={{
                      top: "-.1em",
                      position: "relative",
                      marginLeft: ".5em",
                    }}
                    width="16"
                    height="16"
                    viewBox="-3 0 262 262"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid"
                  >
                    <path
                      d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                      fill="#4285F4"
                    />
                    <path
                      d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                      fill="#34A853"
                    />
                    <path
                      d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                      fill="#FBBC05"
                    />
                    <path
                      d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                      fill="#EB4335"
                    />
                  </svg>
                </span>
              </button>
              <div
                onClick={this.props.registerClicked}
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  color: "#c4c4c4",
                  cursor: "pointer",
                }}
              >
                New user ? Please Register
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  forgotPasswordClicked: PropTypes.func,
  registerClicked: PropTypes.func,
  verifyClicked: PropTypes.func,
};

const mapDispatchToProps = {
  userLogin,
};
const mapStoreToProps = (state) => ({
  login_loading: state.login.login_loading,
  login_error: state.login.error,
});

export default connect(mapStoreToProps, mapDispatchToProps)(LoginForm);
