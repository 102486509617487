import API from "../../axios/API";
import Auth from "../../modules/Auth";

export const setLang = (lang) => dispatch => {
  console.log("lang", lang);
  dispatch({
    type: SET_LANG,
    payload: lang
  });
  localStorage.setItem("lang", lang);
}
export const getLang = () => dispatch => {
  dispatch({
    type: GET_LANG
  });
  return localStorage.getItem("lang");
};


export const setActiveLink = (link) => dispatch => {
  console.log("lang", link);
  dispatch({
    type: SET_ACTIVE_LINK,
    payload: link
  });
}

export const getActiveLink = () => dispatch => {
  dispatch({
    type: GET_ACTIVE_LINK
  });
};


export const SET_LANG = "SET_LANG";
export const GET_LANG = "GET_LANG";

export const SET_ACTIVE_LINK = "SET_ACTIVE_LINK";
export const GET_ACTIVE_LINK = "GET_ACTIVE_LINK";